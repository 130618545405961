<template>
  <div>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Orders'">
          <template v-slot:preview>
            <div>
              <b-tabs content-class="mt-3">
                <div class="row align-items-center mb-3">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center mb-0">
                        Show
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select>
                        entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-9">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right d-block d-sm-block d-md-flex d-xl-flex d-md-flex"
                    >
                      <label class="d-inline-flex align-items-center mb-0">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                      <i
                        @click="refreshData"
                        class="fa fa-refresh refreshBtn ml-2 cursor-pointer"
                        aria-hidden="true"
                      ></i>

                      <!--                      <div class="ml-2">-->
                      <!--                        <button-->
                      <!--                          class="btn print print-btn"-->
                      <!--                          v-print="printObject"-->
                      <!--                        >-->
                      <!--                          Print-->
                      <!--                        </button>-->
                      <!--                        <button-->
                      <!--                          class="btn print excel-btn"-->
                      <!--                          @click="exportExcel(csvData)"-->
                      <!--                        >-->
                      <!--                          Export to Excel-->
                      <!--                        </button>-->
                      <!--                        <button class="btn print" @click="csvExport(csvData)">-->
                      <!--                          Export to CSV-->
                      <!--                        </button>-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
                <!-- End search -->
                <b-tab
                  title="Orders"
                  class="orderList-wrapper"
                  active
                  @click="printTab('orders')"
                >
                  <div class="card">
                    <div class="card-body p-0">
                      <!-- Table -->
                      <div class="table-responsive mb-0" id="orderPrint">
                        <b-table
                          show-empty
                          :items="orders"
                          :fields="fields"
                          responsive="true"
                          :per-page="0"
                          :current-page="currentPage"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          @filtered="onFiltered"
                          @row-clicked="myRowClickHandler"
                        >
                          <template v-slot:cell(status)="orders_history">
                            <b-badge
                              class="mr-1 badge-font badge-pending"
                              pill
                              variant="warning"
                              >{{ orders_history.item.status }}</b-badge
                            >
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Orders History" @click="printTab('history')">
                  <div class="card">
                    <div class="card-body p-0">
                      <div class="row mb-md-2"></div>
                      <!-- Table -->
                      <div class="table-responsive mb-0" id="orderHistoryPrint">
                        <b-table
                          show-empty
                          :items="orders_history"
                          :fields="fields"
                          responsive="true"
                          :per-page="0"
                          :current-page="currentPage"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          @filtered="onFiltered"
                        >
                          <template v-slot:cell(status)="orders_history">
                            <b-badge
                              v-if="orders_history.item.status === 'Done'"
                              class="mr-1 badge-font"
                              pill
                              variant="success"
                              >{{ orders_history.item.status }}</b-badge
                            >
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalItems"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tabs>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
// import ApiService from "@/core/services/api.service";
import router from "../../router";
import XLSX from "xlsx";
import helpers from "../../helpers";
import axios from "axios";

export default {
  data() {
    return {
      loader: false,
      printObject: {
        id: "orderPrint",
        popTitle: "Orders List",
        extraCss: "https://www.google.com,https://www.google.com",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      totalItems: 5,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: true,
      orders: [],
      excelOrdersArr: [],
      orders_history: [],
      fields: [
        { key: "order_no", label: "Order ID", sortable: true },
        { key: "order_date", label: "Date", sortable: true },
        { key: "user_name", label: "Customer Name", sortable: true },
        { key: "location", label: "Location", sortable: true },
        { key: "amount", label: "Amount", sortable: true },
        { key: "status", label: "Status", sortable: true }
      ],
      restaurant_uuid: ""
    };
  },
  computed: {
    rows() {
      return this.orders.length;
    },
    csvData() {
      return this.orders.map(item => ({
        order_id: item.order_no,
        source: item.order_type.order_type,
        username: item.user.name,
        restaurant_name: item.restaurant.title,
        status: item.status.status,
        created_at: item.order_date
      }));
    }
  },
  components: {
    KTCodePreview
  },
  mounted() {
    document.addEventListener("click", this.close);
    if (
      JSON.parse(localStorage.getItem("currentUser")).roles[0].title ===
      "Business Owner"
    ) {
      this.restaurantData = JSON.parse(localStorage.getItem("currentUser"));
      if (this.restaurantData.restaurant) {
        this.restaurant_uuid = this.restaurantData.restaurant.uuid;
      }
    }
    this.totalRows = this.orders.length;
    this.getAllOrders();
  },
  methods: {
    refreshData() {
      this.getAllOrders();
    },
    getUsers() {
      this.users = [];
    },
    async getAllOrders() {
      this.loader = true;
      // let obj = {
      //   uuid: this.restaurant_uuid
      // };

      await axios
        .get(
          this.$path + "/admin/order/restaurant?page=" + this.currentPage + "&"
        )
        .then(response => {
          this.totalItems = parseInt(response.data.meta.total, 10);
          this.loader = false;
          this.orders = response.data.data;
          // this.orders = data.data.filter(function(el) {
          //   return el.status === "Pending" || el.status === "Progress";
          // });
          this.orders_history = response.data.data.filter(function(el) {
            return el.status === "Done";
          });
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    myRowClickHandler(order) {
      router.push({ name: "orderdetails", params: { orderObject: order } });
    },
    printTab(type) {
      if (type === "orders") {
        this.printObject.id = "orderPrint";
        this.printObject.popTitle = "Orders List";
      } else if (type === "history") {
        this.printObject.id = "orderHistoryPrint";
        this.printObject.popTitle = "Orders History List";
      }
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Orders.csv");
      link.click();
    },
    exportExcel(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Orders.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    }
  },
  watch: {
    currentPage: {
      handler: function() {
        // eslint-disable-next-line no-unused-vars
        this.getAllOrders().catch(error => {});
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>

<style scoped></style>
